#header-desktop {
    width: 100%;
    height: var(--header-height);

    background-color: var(--color-bg-variant);

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.header-desktop__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 0 2rem;
}

.header-desktop__info {
    display: flex;

    background-color: var(--color-bg);
    color: var(--color-primary-variant);
    border: 1px solid var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0 0 0.4rem 0.4rem;
    margin-top: -12px;
}

.header-desktop__mail {
    margin-left: 1rem;
}


.header-desktop__callToAction a {
    background-color: var(--color-bg);
    color: var(--color-primary-variant);
    border: 1px solid var(--color-primary);

    padding: 0.75rem 1.2rem;

    border-radius: 0.4rem;

    cursor: pointer;

    text-transform: uppercase;
}

.header-desktop__callToAction a:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.header-desktop__callToAction a .react-icons {
    vertical-align: baseline;
    margin-left: 0.4rem;
}


.header-desktop__logo {
    display: flex;
    justify-content: center;
}

.header-desktop__logo img {
    width: 390px;
}

.header-desktop__menu {
    text-align: center;
}

.header-desktop__menu a {
    text-transform: uppercase;
    padding: 1rem;
    margin: 0 1rem;
    font-size: 1.4rem;
}

.header-desktop__menu .dropdownlink {
    position: relative;
    display: inline-block;
}

.header-desktop__menu .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: var(--color-bg-variant);
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.header-desktop__menu .dropdown-content a {
    text-decoration: none;
    display: block;

    padding: 0.5rem;
}

.header-desktop__menu .dropdownlink:hover .dropdown-content {
    display: block;
}