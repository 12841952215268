#header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--color-bg-variant);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#header-mobile #logo {
    position: relative;
    left: 1rem;
    width: 50%;
}

.header-mobile__icon-open-menu {
    position: relative;
    right: 1rem;

    font-size: 2rem;
}

.header-mobile__icon-close-menu {
    position: absolute;
    top: 1rem;
    right: 1rem;

    font-size: 2.4rem;
    font-weight: bold;
}

.menu_mobile_panel {
    height: 100%;
    width: 100%;

    z-index: 10;

    position: fixed;
    top: 0;
    left: 0;

    background-color: var(--color-bg-variant);

    padding: 1rem;
}

.menu_mobile_panel a {
    display: block;

    font-size: 1.6rem;
    padding: 0.6rem 2rem;
}

.menu_mobile_panel .dropdown-content a {
    font-size: 1.2rem;
    margin-left: 2rem;
}

.menu_mobile_panel .dropdown-content a:before {
    content: ' > ';
}

.menu_mobile_panel .header-mobile__callToAction {
    position: absolute;
    bottom: 1rem;
    left: 1rem;

    text-align: center;

    width: calc(100% - 2rem);
}

.menu_mobile_panel .header-mobile__callToAction a {
    background-color: var(--color-bg);
    color: var(--color-primary-variant);

    border-radius: 0.4rem;
}