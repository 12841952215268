.layout-prestation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    justify-content: center;

    margin-bottom: 2rem !important;
}

.layout-prestation.right .layout-prestation__image {
    grid-column: 2;
    grid-row: 1;
}

.layout-prestation__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.layout-prestation__description p {
    line-height: 1.5rem;
    text-align: center;
}

.layout-prestation__description p ul {
    text-align: left;
}

.layout-prestation__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-prestation__image img {
    width: 400px;
    border: 2px solid var(--color-primary);
    border-radius: 1rem;
}

.layout-prestation__image figure {
    position: relative;
    width: fit-content;

    overflow: hidden;
}

.layout-prestation__image figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.layout-prestation__image figure:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    .layout-prestation {
        grid-template-columns: 1fr;
        gap: unset;
    }

    .layout-prestation__image img {
        width: 100%;
    }

    .layout-prestation.right .layout-prestation__image {
        grid-column: 1;
    }
}