/* ================ MAPS ================= */

.contact__maps {
    height: 25vh;
    border: 2px solid var(--color-primary);

    margin-bottom: 2rem;
}

/* ================ CONTENT ================= */

.contact__content {
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;

    color: var(--color-primary);
}

/* ================ INFOS ================= */

.contact__options {
    background: transparent;
    padding: 1.2rem;
    margin: 1rem 0;
    border-radius: 0.6rem;
    border: 1px solid var(--color-primary-variant);
    transition: var(--transition);
}

.contact__options:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.contact__options h2 {
    border-bottom: 1px solid var(--color-primary-variant);
    color: var(--color-primary-variant);

    margin-bottom: 1rem;
}

.contact__options h2 span {
    font-style: italic;
    font-size: 0.8rem;
}

.contact__link {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.contact__link a {
    background-color: var(--color-primary-variant);
    color: var(--color-white);

    padding: 0.75rem 1.2rem;

    border-radius: 0.4rem;

    cursor: pointer;

    width: 100%;

    text-transform: uppercase;
    text-align: center;
}

.contact__link a:hover {
    color: var(--color-white);

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.contact__link a .react-icons {
    vertical-align: baseline;
    margin-left: 0.4rem;
}

.contact__infos__label {
    display: inline-block;
    width: 20%;
}

/* ================ FORM ================= */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    margin-top: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-primary);
}

textarea {
    min-height: 292px;
}

button {
    width: max-content;
    display: inline-block;
    color: var(--color-primary-variant);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary-variant);
    transition: var(--transition);
    background: transparent;
}

button:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    .contact__content {
        grid-template-columns: 1fr;
        gap: 2rem;

        margin-bottom: 2rem;
    }

    button {
        width: 100%;
    }
}