.pricing-table__pricing {
    background-color: var(--color-bg-second-variant);

    border: 2px solid var(--color-primary);
    border-radius: 1rem;

    padding: 2rem 0;
    margin-bottom: 2rem !important;
}

.pricing-table__pricing:hover {
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
}

.pricing-table__pricing h2 {
    font-size: 1.8rem;
    text-align: center;
}

.pricing-table__pricing-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.pricing-table__pricing-content p,
.pricing-table__pricing-content h3 {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}

.pricing-table__pricing-content h3 {
    margin-bottom: 0.4rem;
}

.pricing-table__pricing-content h3:after {
    position: absolute;
    top: 100%;

    content: "";
    height: 1px;
    width: 50%;

    background-color: var(--color-primary);
}

.pricing-table__pricing__label {
    width: 40%;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    .pricing-table__pricing-content {
        grid-template-columns: 1fr;
    }
}