@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #e1dfdb;
  --color-bg-variant: #642A41;
  --color-bg-second-variant: #FFFFFF;
  --color-primary: #BB638D;
  --color-primary-variant: #B7688F;
  --color-white: #FFFFFF;
  --color-light: #E5E5E5;

  --header-height: 240px;
  --footer-height: 40px;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  background: var(--color-bg);
  color: var(--color-primary);
  line-height: 1.7;
}

.app-content {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.boxed {
  margin: 0 5rem;
}

/* ============== GENEARL STYLES ============== */
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
  color: var(--color-primary);
  text-align: center;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-primary);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.react-icons {
  vertical-align: middle;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .boxed {
    margin: 0 2rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
/* @media screen and (max-width: 600px) {
} */