.home__section.odd {
    background-color: var(--color-bg-second-variant);
}

.home__section h2 {
    font-size: 2rem;
    text-transform: uppercase;

    text-align: center;
}

/* ================ SLIDER ================= */
.awesome-slider {
    --slider-height-percentage: 40%;
    --slider-transition-duration: 300ms;
    --organic-arrow-thickness: 6px;
    --organic-arrow-border-radius: 6px;
    --organic-arrow-height: 50px;
    --organic-arrow-color: var(--color-bg-variant);
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: var(--color-primary);
    --control-bullet-active-color: var(--color-primary-variant);
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
}

.awssld__bullets {
    bottom: 10px;
    z-index: 2;
}

/* ================ INSTITUT ================= */
.home__section__institut>p {
    text-align: center;
}

.parallax__content {
    position: absolute;
    right: -20%;
    top: 50%;

    width: 700px;
    height: 700px;

    transform: translateY(-30%);

    border-radius: 50%;

    background-color: var(--color-bg-variant);
}

.parallax__content_mobile {
    display: none;
}

.parallax__content__text1 {
    position: absolute;
    top: 18%;
    left: 14%;
    padding-right: 45%;

    text-align: right;
    line-height: 1.1rem;
}

.parallax__content__text2 {
    position: absolute;
    top: 45%;
    left: 2rem;
    padding-right: 45%;

    text-align: right;
    line-height: 1.1rem;
}

/* ================ PARTNERS ================= */
.home__section__partners {
    padding: 0 2rem;
}

.slick-slide>div {
    padding: 1rem;
}

/* ================ PRESTATIONS ================= */

.home__section__prestations {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;

    padding: 0 2rem 2rem 2rem;
}

.home__prestations__card {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    font-size: 1.2rem;
    text-transform: uppercase;

    height: 300px;

    border-radius: 10px;

    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;

    cursor: pointer;
}

.home__prestations__card.beaute-mains-pieds,
.home__prestations__card.soins-visage,
.home__prestations__card.jolis-compliments {
    background-position: center;
}

.home__prestations__card span {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 20%;
    background-color: rgba(100, 42, 65, 0.9);
}

/* ================ TEAM ================= */
.home__section__team {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* gap: 2rem; */

    padding-bottom: 2rem;
}

.home__team__person {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home__team__name {
    font-size: 1.6rem;
}

.home__team__libelle {
    margin-top: -10px;
}

.home__team__person img {
    width: 300px;
    height: 320px;
    border-radius: 50%;

    border: 3px solid var(--color-primary);

    margin-bottom: 1rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
    .home__section__prestations {
        grid-template-columns: repeat(2, 1fr);
    }

    .parallax__content {
        right: -40%;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    .home__section__prestations {
        grid-template-columns: 1fr;
    }

    .home__section__team {
        grid-template-columns: 1fr;
    }

    .parallax__content {
        display: none;
    }

    .parallax__content_mobile {
        display: block;
        margin-top: 2rem !important;
        margin-bottom: 2rem !important;
    }
}

/* ================ MEDIA QUERIES (BIG DEVICES) ================= */
@media screen and (min-width: 1400px) {
    .parallax__content {
        right: -20%;
    }
}

@media screen and (min-width: 1600px) {
    .parallax__content {
        right: -18%;
    }
}

@media screen and (min-width: 1800px) {
    .parallax__content {
        right: -16%;
    }
}

@media screen and (min-width: 1900px) {
    .parallax__content {
        right: -14%;
    }
}

@media screen and (min-width: 2200px) {
    .parallax__content {
        right: -10%;
    }
}