#footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 2rem;

    width: 100%;
    height: var(--footer-height);

    background-color: var(--color-bg-variant);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#footer .socials .react-icons {
    font-size: 2rem;

    padding: 0.2rem;
    margin: 0.1rem;

    cursor: pointer;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
    #footer {
        text-align: center;

        flex-direction: column;
        height: auto;
    }

    #footer .copyright {
        margin: 1rem 0;
    }
}